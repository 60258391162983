/* Styles for the ServiceCardGroup component */
.service-group-container {
  margin-bottom: 40px;
  color: #454545;
}

.service-group-container h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #454545;

}

.service-group-container .row {
  margin-right: -15px;
  margin-left: -15px;
}
.service-group-container .col-lg-6 {
  padding: 0 15px;
  margin-bottom: 30px;
}
.service-group-container .col-md-4 {
  padding: 0 15px;
}

@media (max-width: 991px) {
  .service-group-container .col-md-4 {
    margin-bottom: 30px;
  }
}

@media (min-width: 992px) {
  .service-group-container .row::after {
    content: '';
    display: table;
    clear: both;
  }

  .service-group-container .col-md-4 {
    float: left;
    width: 50%;
    margin-bottom: 30px;
  }
}
