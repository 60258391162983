/* AboutPage.css */
.careers-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 0;
  .row {
    width: 100%;
  }
  color: #454545;
}

.careers-container .form-heading {
  text-align: center;
}
.careers-container .form-content {
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: large;
}
.careers-form {
  width: 100%;
  border: 3px solid #ddd;
  padding: 20px;
  position: relative;
  box-shadow: 0px -25px 20px -20px rgba(0, 0, 0, 0.45),
    25px 0 20px -20px rgba(0, 0, 0, 0.45),
    0px 25px 20px -20px rgba(0, 0, 0, 0.45),
    -25px 0 20px -20px rgba(0, 0, 0, 0.45);
}

.careers-form .form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.careers-form .form-group .form-label {
  margin-bottom: 5px;
}

.careers-form .form-control {
  width: 100%;
  padding: 10px;
}

.careers-form .btn {
  text-align: center;
  margin-top: 20px;
  background-color: rgb(220, 95, 0);
  border: 1px solid rgb(220, 95, 0);
}

.form-header {
  position: relative;
  width: 100%;
  height: 450px;
  background-image: url("../assets/images/careers-header.png");
  background-size: cover;
}
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.content h2 {
  margin-bottom: 10px;
}

.content p {
  font-size: 18px;
}
