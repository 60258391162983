.footer-container{
    padding: 3rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #000;
    color: #808080;
}
.footer-col{
    display: flex;
    flex-direction: column;
    justify-content:center ;
    align-items: center;
}
.footer-p{
    color:#808080
}

.footer-links {
    color:#808080;
    text-decoration: none;
}
.footer-ul{
    color: #808080;
}
.footer-ul li .footer-links:hover{
    color: rgb(220, 95, 0);
}