.accordion-body {
  background-color: rgb(220, 95, 0);
  color: white;
}
.accordion-header {
  .accordion-button {
    background-color: rgb(220, 95, 0);
    color: white;
  }

  .accordion-button:focus {
    box-shadow: none;
  }

  .accordion-button:not(.collapsed) {
    background-color: rgb(220, 95, 0);
    color: white;
  }
  .accordion-button::after {
    content:none;
  }
}

.vmc-icon {
  margin-right: 0.5rem;
}
