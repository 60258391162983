.it-staff-augmentation-container {
    margin: 10px 0 0 40px;
    color: #454545;
  }
  .it-staff-augmentation-container .header-img {
    margin-bottom: 10px;
  }
  .it-staff-augmentation-container h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #454545;
  }
  
  .it-staff-augmentation-container h5 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .it-staff-augmentation-container p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  