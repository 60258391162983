/* Devops.css */

.devops-container {
    margin-top: 50px;
    margin-bottom: 50px;
    color: #454545;
  }
  
  .devops-container h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #454545;
  }
  
  .devops-container p {
    margin-bottom: 15px;
  }
  
  .devops-container h5 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .devops-container ul {
    text-align: left;
    margin: 0;
    padding-left: 20px;
  }
  
  .devops-container li {
    margin-bottom: 5px;
  }