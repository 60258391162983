.about-container {
    margin-top: 50px;
    margin-bottom: 50px;
    color: #454545;
  }
  .about-img{
    width:100%;
    margin-bottom: 10px;
  }
  
  h3 {
    font-size: 24px;
    font-weight: bold;
    color: #454545;
    margin-bottom: 20px;
  }

  h6 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 16px;
    color: #454545;
    margin-bottom: 20px;
  }
  
  .line-break{
    width: 3rem;
    height: 0.1rem;
    border: 0;
    border-top: 2px solid rgb(220, 95, 0);
    opacity:1;
  }
  @media (max-width: 767px) {
    /* Adjust styles for smaller screens */
    h3 {
      font-size: 20px;
    }
    
    h6 {
      font-size: 16px;
    }
    
    p {
      font-size: 14px;
    }
  }
  
  