/* MasterVendorProgram.css */

.master-vendor-program-container {
    margin-top:10px;
    margin-bottom: 40px;
    color: #454545;
  }
.header-img {
  margin-bottom: 10px;
  width: 100%;
}
  .master-vendor-program-container h3 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #454545;
  }
  
  .master-vendor-program-container p {
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  .master-vendor-program-container ul {
    list-style: disc;
    padding: 0;
    margin: 0;
    text-align: left;
  }
  
  .master-vendor-program-container ul li {
    margin-bottom: 10px;
  }
  
  