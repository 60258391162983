/* Styles for the Hero component */
.hero-section {
  padding-top: 20px;
  padding-bottom: 60px;
  background-color: #f8f9fa;
  color: #454545;
}

.hero-section h1 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #454545;
}

.hero-section p {
  font-size: 18px;
  margin-bottom: 30px;
}
.hero-section .btn:hover {
  background-color: rgb(220, 95,0);
  font-size: 18px;
  padding: 12px 24px;
  color: white;
  border: rgb(220, 95,0);
}
.hero-section .btn {
  background-color: transparent;
  font-size: 18px;
  padding: 12px 24px;
  color: rgb(220, 95,0);
  border: 2px solid rgb(220, 95,0);
}
.hero-section img {
  max-width: 100%;
  height: auto;

}

.hero-section .row {
  margin-bottom: 20px;
}

.hero-section .intro-section .btn {
  margin-bottom: 10px
}

.service-group-section h6 {
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
  color: #454545;
}

.service-group-section h4 {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #454545;
}

.outro-section{
  background-color: rgb(220, 95,0);
  color: white;
  border-radius: 10px;
  padding: 10px
}
.outro-section h6 {
  text-align: center;
  font-size: 16px;
  margin: 20px;
}

.outro-section h4 {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
}

.outro-section h5 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}
.outro-section .p{
  color: white;
}
.contact-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact-section h2 {
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
}

.contact-section .btn:hover {
  background-color: rgb(220, 95,0);
  font-size: 18px;
  color:white;
  padding: 12px 24px;
  border: 2px solid rgb(220, 95,0);
}
.contact-section .btn {
  background-color: white;
  font-size: 18px;
  color:rgb(220, 95,0);
  padding: 12px 24px;
  border: 2px solid rgb(220, 95,0);
}

.icon-button {
  margin-bottom: 3px;
}
