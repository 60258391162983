.card{
  border: none;
}
.card-body {
  border:rgb(220, 95, 0) 2px solid;
  border-radius: 5px;
}
.card-body .btn {
  background-color: rgb(220, 95, 0);
  font-size: 18px;
  padding: 12px 24px;
  border: rgb(220, 95, 0);
}

