.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    color: #454545;
  }
  .contact-container .contact-row {
    min-width: 300px;
  }
  .contact-container h2 {
    margin-top: 10px;
    text-align: center;
  }

  .contact-container h4 {
    text-align: center;
  }
  
  .contact-container p {
    text-align: center;
    margin-bottom: 20px;
  }
 
  .contact-container form .form-group {
    margin-bottom: 20px;
  }
  
  .contact-container form button[type="submit"] {
    background-color: rgb(220, 95, 0);
    border: 1px solid rgb(220, 95, 0);
    margin: 10px;
    width: 100%;
  }
  .contact-container .contact-line-break {
    height: 0.1rem;
    border: 0;
    border-top: 2px solid rgb(220, 95, 0);
    opacity:1;
  }
  