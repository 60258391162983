.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.services-container h5 {
  color: #555;
  text-align: center;
}

.services-container h3 {
  margin: 20px 0;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
}
