/* ApplicationDevelopmentMaintainance.css */

.application-dev-maintainence-container {
    margin-top: 50px;
    margin-bottom: 50px;
    color: #454545;
  }
  
  .application-dev-maintainence-container h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #454545;

  }
  
  .application-dev-maintainence-container p {
    margin-bottom: 15px;
    color: #454545;
  }
  
  .application-dev-maintainence-container h5 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #454545;
  }
  
  .application-dev-maintainence-container ul {
    text-align: left;
    margin: 0;
    padding-left: 20px;
  }
  
  .application-development-container li {
    margin-bottom: 5px;
    color: #454545;
  }
  